// import _ from 'lodash-es'
import dayjs from 'dayjs'
import LedgerApi from '@/api/LedgerApi'
import { type GridApi } from '@ag-grid-community/core'
import { FinancialConfigType } from '#/FinancialsTypes'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { JournalCreateByType, TreasuryAccountMappingType } from '#/LedgerTypes'

dayjs.extend(isSameOrAfter)
dayjs.extend(quarterOfYear)
export const useLedgerStore = defineStore({
  id: 'ledgerStore ',
  state: () => {
    const ledgerState: {
      financialFieldConfig: FinancialConfigType[]
      loading: Boolean
      agGridApi: GridApi | null
      treasuryAccountMappingList: TreasuryAccountMappingType[]
      accountBalanceGroupFilter: any[]
      journalCreatedByList: JournalCreateByType
    } = {
      financialFieldConfig: [],
      treasuryAccountMappingList: [],
      agGridApi: null,
      loading: true,
      accountBalanceGroupFilter: [],
      journalCreatedByList: {
        members: [],
        transactionAutomationRules: {
          transfer: [],
          trade: [],
          businessData: [],
          gainLoss: []
        },
        valuationAutomationRules: []
      }
    }
    return ledgerState
  },
  actions: {
    /**
     * 获取general-ledger列表
     * @param {string} entityId
     * @param {Object} params
     * @property {string} chartOfAccountId chartOfAccountId
     * @property {number} page 1
     * @property {number} limit 20
     * @returns
     */
    async fetchGeneralLedgerList(entityId: string, params: any) {
      try {
        const { data } = await LedgerApi.getGeneralLedgerList(entityId, params)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取SubsidiaryLedger列表
     * @param {string} entityId
     * @param {Object} params
     * @property {string} accountId accountId
     * @property {number} page 1
     * @property {number} limit 20
     * @returns
     */
    async fetchSubsidiaryLedgerList(entityId: string, params: any) {
      try {
        const { data } = await LedgerApi.getSubsidiaryLedgerList(entityId, params)
        return { list: data }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取treasuryAccountMapping
     * @param {string} entityId 主体id
     */
    async fetchTreasuryAccountMappingList(entityId: string) {
      try {
        const { data } = await LedgerApi.getTreasuryAccountMappingList(entityId)
        this.treasuryAccountMappingList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务过程表配置数据初始化
     * @param {string} entityId
     * @param {string} type 报表类型 (general-ledger/holdings)
     * @param {Object} params
     * @property {string} periodBegin
     * @property {string} periodEnd
     * @returns
     */
    async processTableDataInit(entityId: string, type: string, params: object) {
      try {
        let response: any = {}
        switch (type) {
          case 'general-ledger':
            response = await LedgerApi.getGeneralLedgerSummary(entityId, params)
            response.data = response.data.filter((item: any) => item.currencyList?.length)
            break
          default:
            response = await LedgerApi.getSubsidiaryLedgerSummary(entityId, params)
            break
        }
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 财务过程表数据初始化
     * @param {string} entityId
     * @param {string} type 报表类型 (general-ledger/transactions/contactsUnnamed)
     * @param {number | string} paramsId 个别接口参数需要id (accountId)
     * @returns
     */
    async processTableListDataInit(entityId: string, type: string, params?: any) {
      try {
        let response: any = {}
        switch (type) {
          case 'general-ledger':
            response = await this.fetchGeneralLedgerList(entityId, params)
            break
          case 'account-balance':
            response = await this.fetchSubsidiaryLedgerList(entityId, params)
            break
          default:
            break
        }
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 设置Ag-Grid的Api
     * @param {GridApi} api
     */
    setAgGridApi(api: GridApi) {
      this.agGridApi = api
    },
    /**
     * 编辑Account Balance分组筛选
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editAccountBalanceGroupFilter(entityId: string, data: object) {
      const currentIndex = this.accountBalanceGroupFilter.findIndex((item: any) => item.entityId === entityId)
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.accountBalanceGroupFilter.splice(currentIndex, 1)
        } else {
          this.accountBalanceGroupFilter.splice(currentIndex, 1, {
            entityId,
            data
          })
        }
      } else if (filterLength) {
        this.accountBalanceGroupFilter.push({ entityId, data })
      }
    },
    /**
     * 获取JournalCreatedBy列表
     * @param {string} entityId 主体id
     */
    async fetchJournalCreatedByList(entityId: string) {
      try {
        const { data } = await LedgerApi.getJournalCreatedByList(entityId)
        this.journalCreatedByList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['accountBalanceGroupFilter']
      }
    ]
  }
})
